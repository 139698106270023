import React from "react";

import { slugify } from "utils/formatting";

import Box from "components/Box";
import Button from "components/Button";
import Form from "components/Form";
import FormField from "components/FormField";
import Text from "components/Text";

const fieldStyles = {
  text: {},
  textarea: {
    gridColumn: "1/-1",
  },
};

const statuses = {
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

function getDefaultValues(fields) {
  return Object.fromEntries(
    fields.map(({ name, type }) => {
      let defaultValue = "";
      if (type === "select" || type === "tiles") {
        defaultValue = undefined;
      }
      return [name, defaultValue];
    })
  );
}

const ContactForm = ({ formFields = [], slug, submitValue, className, sx }) => {
  const name = slugify(slug.current);
  const [status, setStatus] = React.useState();

  const handleSubmit = React.useCallback(
    (data, event) => {
      setStatus(statuses.SUBMITTING);

      const options = {
        method: "POST",
        body: new FormData(event.target),
      };

      fetch("/", options)
        .then(response => {
          if (response.ok) {
            setStatus(statuses.SUCCESS);
          } else {
            setStatus(statuses.FAILURE);
          }
        })
        .catch(() => {
          setStatus(statuses.FAILURE);
        });
    },
    [setStatus]
  );

  const props = { className, sx };

  if (status === statuses.SUBMITTING) {
    return (
      <Box {...props}>
        <Text>Submitting…</Text>
      </Box>
    );
  }

  if (status === statuses.SUCCESS) {
    return (
      <Box {...props}>
        <Text>Thank you for your submission.</Text>
      </Box>
    );
  }

  if (status === statuses.FAILURE) {
    return (
      <Box {...props}>
        <p>
          <strong>Something went wrong.</strong>
        </p>
        <p>
          Sorry, your form couldn't be submitted due to a server problem. Please
          try again later.
        </p>
      </Box>
    );
  }

  return (
    <Form
      action="#"
      data-netlify={true}
      defaultValues={getDefaultValues(formFields)}
      method="POST"
      name={name}
      onSubmit={handleSubmit}
      {...props}
    >
      {formFields.map(field => (
        <FormField
          key={field.name}
          sx={{ ...fieldStyles[field.type] }}
          {...field}
        />
      ))}
      <input type="hidden" name="form-name" value={name} />
      <Button type="submit">{submitValue}</Button>
    </Form>
  );
};

export default ContactForm;
