import React from "react";

import Text from "components/Text";

const blocks = {
  h1: ({ children }) => (
    <Text as="h1" variant="styles.h1">
      {children}
    </Text>
  ),
  h2: ({ children }) => (
    <Text as="h2" variant="styles.h2">
      {children}
    </Text>
  ),
  h3: ({ children }) => (
    <Text as="h3" variant="styles.h3">
      {children}
    </Text>
  ),
  normal: ({ children }) => (
    <Text as="p" variant="styles.p">
      {children}
    </Text>
  ),
  display1: ({ children }) => (
    <Text as="p" variant="text.display1">
      {children}
    </Text>
  ),
  display2: ({ children }) => (
    <Text as="p" variant="text.display2">
      {children}
    </Text>
  ),
  blockquote: ({ children }) => (
    <Text as="blockquote" variant="styles.blockquote">
      {children}
    </Text>
  ),
};

export { blocks };
