import React from "react";

import { getSlug } from "utils/formatting";

import Link from "components/Link";
import Button from "components/Button";
import Text from "components/Text";

const marks = {
  italic: ({ children }) => <em sx={{ variant: "styles.em" }}>{children}</em>,
  em: ({ children }) => <em sx={{ variant: "styles.em" }}>{children}</em>,
  bold: ({ children }) => (
    <Text as="strong" sx={{ variant: "styles.strong" }}>
      {children}
    </Text>
  ),
  strong: ({ children }) => (
    <Text as="strong" sx={{ variant: "styles.strong" }}>
      {children}
    </Text>
  ),
  link: ({ mark, children }) => <Link to={mark.href}>{children}</Link>,
  internalLink: ({ mark, children }) => (
    <Link to={getSlug(mark.reference)}>{children}</Link>
  ),
  lushootseed: ({ children }) => (
    <Text as="span" sx={{ fontFamily: "lushootseed" }}>
      {children}
    </Text>
  ),
  button: ({ children, mark }) => {
    const { to, href, variant, icon } = mark || {};
    return (
      <Button
        to={to}
        href={href}
        variant={[variant, "inline"]}
        icon={icon}
        children={children}
      />
    );
  },
};

export { marks };
