import React from "react";

import Box from "components/Box";

import FormProvider, { useGetValues } from "./FormProvider";

const Form = ({ defaultValues, onSubmit, preventDefault = true, ...props }) => {
  return (
    <FormProvider defaultValues={defaultValues}>
      <FormComponent
        onSubmit={onSubmit}
        preventDefault={preventDefault}
        {...props}
      />
    </FormProvider>
  );
};

function FormComponent({ onSubmit, preventDefault, ...props }) {
  const getValues = useGetValues();

  function handleSubmit(event) {
    if (preventDefault) {
      event.preventDefault();
    }
    if (onSubmit) {
      onSubmit(getValues(), event);
    }
  }

  return <Box as="form" onSubmit={handleSubmit} {...props} />;
}

export default Form;
