import React from "react";
import sanityClient from "picosanity";
import { getGatsbyImageData } from "gatsby-source-sanity";
import imageUrlBuilder from "@sanity/image-url";
import useSWR from "swr";

const config = {
  apiVersion: "2021-03-25",
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  token: process.env.GATSBY_SANITY_API_TOKEN,
  useCdn: true,
};

const client = sanityClient(config);
const imageBuilder = imageUrlBuilder(config);
const getImageURL = source => {
  return imageBuilder.image(source);
};

function useSanityClient() {
  return client;
}

function useGetSanityImage() {
  return getImageURL;
}

function useGetGatsbyImageData() {
  return React.useCallback((asset, args) => {
    return getGatsbyImageData(asset._id || asset._ref, args, config);
  }, []);
}

// if you're stuck on `isValidating: true`, try memoizing your arguments
function useSanityGROQQuery(query, variables) {
  const result = useSWR(
    client && query && [query, variables],
    (query, variables) => client.fetch(query, variables)
  );

  return result;
}

export {
  useGetGatsbyImageData,
  useGetSanityImage,
  useSanityClient,
  useSanityGROQQuery,
};
