import React from "react";

import { styled } from "style";

import Figure from "components/Figure";
import Image from "components/Image";

const StyledImage = styled(Image, {
  forwardProps: ["asset"],
})({
  borderRadius: "md",
  overflow: "hidden",
});

function ImageBlock({ node, lightbox, ...props }) {
  const { alt, caption, image } = node;

  if (caption) {
    return (
      <Figure caption={caption} {...props}>
        <StyledImage alt={alt} {...image} {...props} />
      </Figure>
    );
  }

  return <StyledImage alt={alt} {...image} {...props} />;
}

export default ImageBlock;
