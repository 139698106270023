import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useThemeUI } from "theme-ui";
import { getColor } from "@theme-ui/color";

import { styled } from "style";
import { useGetGatsbyImageData } from "state/sanity";

const defaultProps = {
  alt: "",
  layout: "fullWidth",
};

const gatsbyImageProps = [
  "loading",
  "className",
  "image",
  "imgClassName",
  "imgStyle",
  "backgroundColor",
  "objectFit",
  "objectPosition",
];

const StyledImage = styled("img", {
  forwardProps: gatsbyImageProps,
})({ display: "block" });

const Image = React.forwardRef(
  ({ _type, asset, layout, height, width, src, ...props }, ref) => {
    const { theme } = useThemeUI();
    const getImageData = useGetGatsbyImageData();

    if (asset) {
      const { aspectRatio } = asset.metadata?.dimensions || {};
      const args = { width, height, placeholder: "dominantColor", layout };
      if (!args.width) {
        args.width = theme.sizes.lg;
      }

      if (!args.height && aspectRatio) {
        args.height = args.width && Math.round(args.width / aspectRatio);
      }

      const image = getImageData(asset, args);

      if (image) {
        return (
          <StyledImage
            as={GatsbyImage}
            ref={ref}
            image={image}
            backgroundColor={getColor(theme, "violet.50")}
            {...props}
          />
        );
      }
    }

    if (src) {
      return <StyledImage ref={ref} src={src} {...props} />;
    }

    return <strong>Couldn't retrieve image.</strong>;
  }
);

export default Image;

Image.defaultProps = defaultProps;
