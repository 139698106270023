import React from "react";

import { styled } from "style";

import Box from "components/Box";

const Figcaption = styled("figcaption")({
  color: "textMuted",
  fontSize: 0,
  fontStyle: "italic",
  textAlign: "center",
  py: 1,
});

const Figure = ({ caption, children, ...props }) => {
  return (
    <Box as="figure" {...props}>
      {children}
      <Figcaption>{caption}</Figcaption>
    </Box>
  );
};

export default Figure;
