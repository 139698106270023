import React from "react";
import SanityBlockContent from "@sanity/block-content-to-react";

import { styled } from "style";

import { blocks } from "./blocks";
import { types } from "./types";
import { marks } from "./marks";

const StyledContent = styled("div")({
  "h2:not(:first-child)": {
    mt: 5,
  },
  "h3:not(:first-child)": {
    mt: 4,
  },
  ul: {
    listStyle: "disc",
    mb: 3,
    pl: 3.5,
  },
});

const BlockRenderer = props => {
  const element = blocks[props.node.style];
  if (element) {
    return React.createElement(element, props);
  }

  return SanityBlockContent.defaultSerializers.types.block(props);
};

const serializer = {
  types: {
    block: BlockRenderer,
    ...types,
  },
  marks,
};

const BlockContent = ({ blocks, container: _container, ...props }) => {
  let container = _container;
  if (!container) {
    container = _props => <StyledContent {..._props} {...props} />;
  }

  return (
    <SanityBlockContent
      blocks={blocks}
      renderContainerOnSingleChild={true}
      serializers={{ ...serializer, container }}
    />
  );
};

export default BlockContent;
