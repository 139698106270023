import React from "react";
import { useField } from "@zendeskgarden/container-field";
import loadable from "@loadable/component";

import { getThemeProps } from "style";
import getAutocomplete from "utils/getAutocomplete";

import { useError, useValue } from "components/Form";
import Box from "components/Box";
import Skeleton from "components/Skeleton";

const style = {
  field: {
    display: "flex",
    flexFlow: "column nowrap",
    mb: 4,
  },
  label: {
    width: "100%",
    display: "inline-block",
    fontSize: 1,
    fontWeight: 800,
    color: "gray.500",
    mb: 2,
    "[data-invalid] &": {
      color: "brick.900",
    },
  },
  hint: {
    mt: 1,
    order: 1,
    color: "gray.400",
    fontSize: 0,
    fontStyle: "italic",
    "[data-invalid] &": {
      color: "brick.900",
    },
  },
};

const components = {
  select: loadable(() => import("components/Listbox")),
  number: loadable(() => import("components/Counter")),
  textarea: loadable(() => import("components/Textarea")),
  tiles: loadable(() => import("components/Tiles")),
  textInput: loadable(() => import("components/TextInput")),
};

function getError(el) {
  if (el?.validity) {
    for (let key in el.validity) {
      if (el.validity[key]) {
        return {
          type: key,
          message: el.validationMessage,
        };
      }
    }
  }
}

function getDescription(...args) {
  const parts = args.filter(Boolean);
  return parts.length > 0 ? parts.join(". ") : undefined;
}

function getComponent(type = "textInput") {
  const aliases = {
    counter: "number",
    listbox: "select",
  };
  const name = aliases[type] || type;
  return components[name] || components["textInput"];
}

const FormField = React.forwardRef(
  (
    {
      className,
      hint,
      label,
      name,
      onChange,
      required,
      sx,
      type,
      variant,
      ...props
    },
    ref
  ) => {
    const { getLabelProps, getInputProps, getHintProps } = useField(name);
    const [value, setValue] = useValue(name);
    const [error, setError] = useError(name);
    const Component = getComponent(type);
    const themeProps = getThemeProps({
      style,
      themeKey: "forms",
      variant,
    });

    function handleChange(next) {
      setError();
      setValue(next);
      if (onChange) {
        onChange(next);
      }
    }

    function handleInvalid(event) {
      setError(getError(event.currentTarget));
    }

    const fallback = (
      <Skeleton __themeKey={`forms.${type}`} variant={variant || "default"} />
    );

    const description = getDescription(hint, error?.message);

    return (
      <Box
        className={className}
        data-required={required || undefined}
        data-invalid={error || undefined}
        sx={sx}
        variant="field"
        {...themeProps("field")}
      >
        <Box as="label" {...themeProps("label")} {...getLabelProps()}>
          {label}
        </Box>
        {description && (
          <Box as="span" {...themeProps("hint")} {...getHintProps()}>
            {description}
          </Box>
        )}
        <Component
          autocomplete={getAutocomplete(name)}
          fallback={fallback}
          name={name}
          onChange={handleChange}
          onInvalid={handleInvalid}
          ref={ref}
          required={required}
          type={type}
          value={value}
          variant={variant}
          {...getInputProps({}, { isDescribed: !!hint })}
          {...props}
        />
      </Box>
    );
  }
);

export default FormField;
