import React from "react";

import { styled } from "style";

import ImageBlock from "./Image";

const Container = styled("div")({
  display: "flex",
  flexWrap: "row wrap",
  gap: 3,
  my: 4,
  mx: [0, 0, -5],
});

const Item = styled("div")({
  position: "relative",
  minHeight: 200,
  overflow: "hidden",
  "& > div, & figure > div": {
    my: 0,
  },
});

function GalleryBlock({ node }) {
  const { images } = node;
  return (
    <Container>
      {images.map(image => {
        const { aspectRatio } = image.image.asset.metadata.dimensions;
        return (
          <Item key={image._key} style={{ flex: aspectRatio }}>
            <ImageBlock node={image} />
          </Item>
        );
      })}
    </Container>
  );
}

export default GalleryBlock;
