import ButtonBlock from "./Button";
import GalleryBlock from "./Gallery";
import ImageBlock from "./Image";
import FormBlock from "./Form";

const types = {
  buttonBlock: ButtonBlock,
  galleryBlock: GalleryBlock,
  imageBlock: ImageBlock,
  formBlock: FormBlock,
};

export { types };
