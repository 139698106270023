import React from "react";

import { getSlug } from "utils/formatting";

import Button from "components/Button";
import Text from "components/Text";

function ButtonBlock({ node }) {
  const { text, to, href, variant, icon } = node;
  const url = to ? getSlug(to) : href;
  return (
    <Text as="p">
      <Button variant={variant} to={url} icon={icon} children={text} />
    </Text>
  );
}

export default ButtonBlock;
