import React, { useCallback } from "react";
import create from "zustand";
import createContext from "zustand/context";
import shallow from "zustand/shallow";

const { Provider, useStore } = createContext();

function createStore({ defaultValues = {} }) {
  return create((set, get) => ({
    errors: {},
    setError: (name, error) => {
      if (name) {
        set(prev => ({ errors: { ...prev.errors, [name]: error } }));
      }
    },
    clearError: name => {
      if (name) {
        get().setError(name, undefined);
      } else {
        set({ errors: {} });
      }
    },
    values: defaultValues,
    setValue: (name, value) => {
      if (!name) {
        throw new Error("setValue() called without a name.");
      }
      set(prev => {
        const next =
          typeof value === "function" ? value(prev.values[name]) : value;
        return { values: { ...prev.values, [name]: next } };
      });
    },
    getValues: name => {
      const values = get().values;
      return name ? values[name] : values;
    },
  }));
}

export default function FormProvider({ children, defaultValues }) {
  return (
    <Provider createStore={() => createStore({ defaultValues })}>
      {children}
    </Provider>
  );
}

export function useError(name) {
  const [error, setError] = useStore(
    useCallback(state => [state.errors[name], state.setError], [name]),
    shallow
  );
  return [error, useCallback(value => setError(name, value), [name, setError])];
}

export function useValue(name) {
  const [value, setValue] = useStore(
    useCallback(state => [state.values[name], state.setValue], [name]),
    shallow
  );
  return [value, useCallback(next => setValue(name, next), [name, setValue])];
}

export function useGetValues() {
  return useStore(useCallback(state => state.getValues, []));
}
